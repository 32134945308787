import React from 'react';
import Project from './Project';
import { NavLink } from 'react-router-dom';
import { projects } from './data';

export default function Projects({ handleColorApp, CardContainer, HeaderContainer, Cards, Body, Text }) {

    return (
        <div className={CardContainer}>
            <h1 className={HeaderContainer}>{'<'}Projects{'>'}</h1>
            <div className={Cards}>
                {projects.map(proj => (
                    <NavLink to={`/projects/${proj.link}`} style={{ textDecoration: 'none' }}>
                        <Project
                            title={proj.title}
                            image={proj.image}
                            description={proj.description}
                            handleColorApp={handleColorApp}
                            Body={Body}
                            Text={Text}
                        />
                    </NavLink>
                ))}

            </div>
        </div>
    )
}